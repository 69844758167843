.App {
	text-align: center;
	background-color: #050001;
	min-height: 100vh;
	min-width: 100vw;
}

.App-logo {
	height: 60vmin;
	width: 100%;
	object-fit: cover;
	pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
	background-color: #050001;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.Bumper {
	font-size: calc(10px + 2vmin);
	font-family: 'DotGothic16', sans-serif;
	color: white;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
